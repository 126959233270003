import {get,post} from "@/utils/axios"
// 判断是否关注
const isFollowByObjectId1 = p => post('/api-common/followPortal/isFollowByObjectId', p);

// 新增关注
const addFollowByObjectId = p => post('/api-seller/follow/addFollow', p);

// 取消关注
const delFollowByObjectId = p => post('/api-common/followPortal/delFollowByObjectId', p)

// 获取商品详情信息
const getSoftwareGoodsInfoById = p => get('/api-common/softwareGoodsPortal/getSoftwareGoodsInfoById', p);

// 查询优惠券列表
const getSoftwareCoupons = p => get('/api-common/couponPortal/getSoftwareCoupons', p)

// 领取优惠券
const syncReceiveCoupon = p => post('/api-common/couponPortal/syncReceiveCoupon', p)

// 保存订单联系信息
const syncAddOrderInfo = p => post('/api-seller/orderInfo/syncAddOrderInfo',p)

// 立即购买,生成订单信息
const syncPlaceAnOrder = p => post('/api-seller/orderInfo/syncPlaceAnOrder',p)

// 加入购物车
const syncAddCar = p => post('api-common/softwareCarPortal/syncAddCar', p)

//相关推荐
const recommondList = p => get('/api-common/softwareGoodsPortal/getLabelGoodsList', p)

export default {
    isFollowByObjectId1,
    addFollowByObjectId,
    delFollowByObjectId,
    getSoftwareGoodsInfoById,
    getSoftwareCoupons,
    syncReceiveCoupon,
    syncAddOrderInfo,
    syncPlaceAnOrder,
    syncAddCar,
    recommondList,
}
