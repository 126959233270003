<template>
  <div class="page flex-col" v-wechat-title="this.title">
    <div class="group_1 flex-col">
      <div class="group_20 flex-col">
        <div class="group_21 flex-row justify-between">
          <a href="/#/softwareList"><span class="text_54">云市场</span></a>
          <img
              class="thumbnail_4"
              referrerpolicy="no-referrer"
              src="../../img/product/productDescription/SketchPnge9688f4305eea94c326977c63c82a87a21443ce8b596db586a297358f819111b.png"
          />
          <span class="text_55">{{productParams[0].name}}</span>
        </div>
      </div>
      <div class="group_22 flex-col">
        <div class="box_1 flex-col"></div>
        <div class="box_3 flex-row justify-between">
          <div class="box_4 flex-col">
            <img
                class="image_2"
                referrerpolicy="no-referrer"
                :src="goodImg"
            />
          </div>
          <div class="box_5 flex-col">
            <div class="text-wrapper_3">
<!--              <span class="text_9">Surface&nbsp;Go</span>-->
              <!-- 商品名称 -->
                <span class="text_10">{{productParams[0].name}}</span>
              <!--  关注 -->
                <a href="javascript:;" class="text_10_follow" :class="{text_10_followed: followFlag}" @click="follow">{{followText}}</a>
            </div>
            <span class="text_11">产品介绍：{{productParams[0].profile}}</span>
            <span class="text_11" style="margin-top: 31px;">服务商：{{productParams[0].shopName}}</span>
            <div class="box_66 flex-row justify-between product_scroll">
              <span class="text_12"><span>选择规格：</span></span>
              <div class="product_scroll">
                <div class="box_6 flex-row justify-between" v-for="(item, index) in productSpe" :key="index">
<!--                <span class="text_12"><span>选择版本：</span></span>-->
                  <div class="flex-col text-wrapper_4" :class="{'select_color':item.isSelect,'no_select_color':!item.isSelect}" @click="selectProduct(item,index)">
                    <span class="text_13" :class="{'select_font_color':item.isSelect,'no_select_font_color':!item.isSelect}">
                      {{item.name}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box_7 flex-row" v-if="marketSpecsName != '永久'">
              <span class="text_19 ">购买方式：</span>
              <div class="flex-col cursor-item" v-for="(item,index) in specsArr" :key="index"
                   :class="{'group_10_border':item.isSelect,'group_10_no_border':!item.isSelect}"
                   @click="selectBuyWay(item,index)">
                <!-- <div class="block_3 flex-row"></div> -->
                <span class="text_buy">{{item.title}}</span>
              </div>
              <!-- <span class="text_21">（年）</span> -->
            </div>
            <div class="box_7 flex-row" v-if="marketSpecsName != '永久'">
              <span class="text_19">购买时长：</span>
              <el-input-number size="small" class="time_input" :min="1" :max="24"  v-model="amount"></el-input-number>
            </div>
            <div class="text-wrapper_9 flex-row justify-between">
              <span class="text_22">合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计：</span>
              <span class="text_23">¥{{totalPrice}}</span>
            </div>
            <!-- 优惠券码 -->
            <div class="text-wrapper_9 flex-row justify-between" v-show="productParams[0].name == '云文档管理系统' || productParams[0].name == '固定资产智能管理'">
              <span class="text_22">优惠券码：</span>
              <input class="coupon-input" type="text" v-model="couponNumber" placeholder="请输入优惠券码">
              <span style="color: #ed4014">{{points}}</span>
            </div>
            <!-- 使用优惠后  -->
            <div class="text-wrapper_9 flex-row justify-between" v-if="productParams[0].isConsult==0" v-show="isCoupon == true">
              <span class="text_22">优惠后：</span>
              <span class="text_23">¥{{discountPrice}}</span>
            </div>
            <div class="box_10 flex-row">
              <button class="text-wrapper_10 flex-col" @click="buyGood" :disabled="buttonDisabled" v-if="showBuy"><span class="text_24">{{buyText}}</span></button>
              <!-- <button class="text-wrapper_11 flex-col" @click="addCar"><span class="text_25">加入购物车</span></button> -->
              <div class="text-wrapper_12 flex-col" :style="{'margin-left': !showBuy ? 0 : '1.78vw'}"><span class="text_26">咨询客服</span></div>
            </div>
          </div>
        </div>



        <div class="group_25 flex-col">
          <div class="text-wrapper_26 flex-row"><span class="text_56">相关推荐</span></div>

          <div class="group_26 flex-row"><div class="block_12 flex-col"></div></div>

          <div class="group_27 flex-row">
            <!--          <div class="box_21 flex-col cursor-item"> <img class="" src="@/img/product/productDescription/left_slices.png" @click="left(loopData0,4)"/> </div>-->

            <div id="father" class="grid-content bg-purple">

              <div class="list_1 flex-row swiper-box" id="swiper-box" :style="{transform:'translateX('+currentOffset+'px)'}">

                <div class="list-items_1 flex-col cursor-item" v-for="(item, index) in loopData0" :key="index" @click="openProductDetail(loopData0[index].marketId)">
                  <!--              <img class="slices-img" :style="{minWidth:width}" src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" />-->
                  <div class="section_7 flex-col" :style="{minWidth:width}">
                    <img
                        class="image_17"
                        referrerpolicy="no-referrer"
                        :src="item.logoUrl"

                    />
                    <!--                <img-->
                    <!--                    v-if="item.slot2 === 2"-->
                    <!--                    class="image_18"-->
                    <!--                    referrerpolicy="no-referrer"-->
                    <!--                    :src="item.specialSlot2.lanhuimage0"-->

                    <!--                />-->
                    <el-tooltip effect="dark" :content="item.name" placement="top-start">
                      <span class="text_57" v-html="item.name"></span>
                    </el-tooltip>
                    <span class="text_58" >订阅版、乐享售后服务</span>
                    <span class="text_59" v-if="item.name === '企业网盘' || item.name === '多云管理平台' || item.name === '多云费用管理平台'">0元试用</span>
                    <span class="text_59" v-else>咨询</span>
                  </div>

                </div>

              </div>

            </div>
            <!--          <div class="box_22 flex-col cursor-item">  <img class="" src="@/img/product/productDescription/right_slices.png" @click="right(loopData0,4)"></img> </div>-->
          </div>

        </div>
        <div class="box_13 flex-col">
          <div class="box_16 flex-col">
            <div class="text-wrapper_20 flex-row justify-between">
              <!-- <span class="text_27">商品介绍<div class="group_11 flex-col"></div></span>-->
              <span class="text_27 cursor-item" :class="{'introduce_select':introduceSelect[0].isSelect,'introduce_no_select':!introduceSelect[0].isSelect}" @click="introduceSelectMethod(introduceSelect[0])">{{introduceSelect[0].content}}<div class="group_11 flex-col" v-if="introduceSelect[0].isSelect"></div></span>
              <span class="text_28 cursor-item" :class="{'introduce_select':introduceSelect[1].isSelect,'introduce_no_select':!introduceSelect[1].isSelect}" @click="introduceSelectMethod(introduceSelect[1])">{{introduceSelect[1].content}}<div class="group_11 flex-col" v-if="introduceSelect[1].isSelect"></div></span>
              <span class="text_29 cursor-item" :class="{'introduce_select':introduceSelect[2].isSelect,'introduce_no_select':!introduceSelect[2].isSelect}" @click="introduceSelectMethod(introduceSelect[2])">{{introduceSelect[2].content}}<div class="group_11 flex-col" v-if="introduceSelect[2].isSelect"></div></span>
              <span class="text_30 cursor-item" :class="{'introduce_select':introduceSelect[3].isSelect,'introduce_no_select':!introduceSelect[3].isSelect}" @click="introduceSelectMethod(introduceSelect[3])">{{introduceSelect[3].content}}<div class="group_11 flex-col" v-if="introduceSelect[3].isSelect"></div></span>
              <span class="text_30 cursor-item" :class="{'introduce_select':introduceSelect[4].isSelect,'introduce_no_select':!introduceSelect[4].isSelect}" @click="introduceSelectMethod(introduceSelect[4])">{{introduceSelect[4].content}}<div class="group_11 flex-col" v-if="introduceSelect[4].isSelect"></div></span>
            </div>
        <!--      商品介绍内容        -->
            <template v-if="introduceSelect[0].isSelect">
              <span v-if="productParams[0].version == null || productParams[0].version == ''" class="text_31">该商品暂无介绍 </span>
              <el-col v-else>
                <el-col>
                <span  class="text_31_html">品牌：{{productParams[0].shopName}}</span>
                </el-col>
                <el-col class="introduce_container first_row">
                  <span>商品名称：{{productParams[0].name}}</span>
                  <span class="margin-left">版本号：{{productParams[0].version}}</span>
                  <span class="margin-left">类型：{{productParams[0].type == 1 ? '镜像' : productParams[0].type == 2 ? '人工服务' :
                      productParams[0].type == 3 ? 'Saas'
                          : productParams[0].type == 4 ? '解决方案' : productParams[0].type == 5 ? 'API' :
                              productParams[0].type == 6 ? 'License' : '其他'}}</span>
                  <span class="margin-left">适用系统：{{productParams[0].adapt == 1 ? 'windows' : productParams[0].adapt == 2 ?
                        'linux' : productParams[0].adapt == 3 ? 'android'
                            : productParams[0].adapt == 4 ? 'ios' : productParams[0].adapt == 5 ? 'unix' :
                                '其他'}}</span>
                </el-col>
                <el-col class="introduce_container other_row">
                  <el-col>商品亮点：<span :key="index" :class="{'margin-bright':index !==0}" v-for="(item,index) in marketBrightInfo">{{item.context}}</span></el-col>
                </el-col>
                <el-col class="introduce_container other_row">
                  <el-table
                        :data="tableInfo"
                        :span-method="objectSpanMethod"
                        border
                        style="width: 100%;"
                        align="center">
                    <el-table-column
                        prop="name"
                        label="规格">
<!--                      <el-table-column v-for="item1 in item.extend1" po></el-table-column>-->
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="计费方式">
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="服务价格">
                    </el-table-column>
                  </el-table>
                </el-col>
                <template v-if="marketId == '08ed8104-2b8a-4c62-a491-6ac63dee398c' || marketId == '6bdf463b-2b97-430e-9b83-df58b908ab44' || marketId == '661ebdcf-a0cb-46b5-8c0b-39e7e872a3d9'">
                  <el-col class="introduce_container other_row">
                    <el-col>商品规格：</el-col>
                  </el-col>
                  <el-col class="introduce_container other_row">
                    <el-table :data="trainTable" border style="width: 100%" align="center">
                      <el-table-column prop="classify" label="项目类别"></el-table-column>
                      <el-table-column prop="product" label="产品"></el-table-column>
                      <el-table-column prop="specs" label="规格">
                        <template v-slot="{row}">
                          <div v-html="row.specs"></div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="dimension" label="量纲"></el-table-column>
                      <el-table-column prop="num" label="数量"></el-table-column>
                      <el-table-column prop="worth" label="价值说明"></el-table-column>
                      <el-table-column prop="price" label="价格"></el-table-column>
                    </el-table>
                  </el-col>
                </template>
              </el-col>
            </template>
        <!--      交易条款内容        -->
            <template v-if="introduceSelect[3].isSelect">
              <span class="text_31">暂无用户评价</span>
            </template>
            <!--      交易条款内容        -->
            <template v-if="introduceSelect[1].isSelect">
              <el-col style="font-size: 14px" class="introduce_container first_row">
                <el-col>
                服务商：{{productParams[0].shopName}}
                </el-col>
                <el-col class="introduce_container other_row">
                联系电话：{{productParams[0].shopPhone}}
                </el-col>
                <el-col class="introduce_container other_row">
                联系邮箱：{{productParams[0].shopEmail}}
                </el-col>
                <el-col class="introduce_container other_row">
                问题处理：  <button class="work-btn cursor-item" @click="toBuyerWork()">提交工单</button>
                </el-col>
              </el-col>
            </template>
        <!--      售后保障内容      -->
            <template v-if="introduceSelect[2].isSelect">
                <span class="text_31">
                  暂无操作指南
                </span>
            </template>
        <!--       授权说明内容     -->
            <template v-if="introduceSelect[4].isSelect">
                <span class="text_31"><p>暂无客户案例。</p></span>
            </template>
          </div>
        </div>
      </div>



    </div>
<!-- 选择个性域名  -->
    <el-dialog :title="productParams[0].name + '个性域名'" :visible.sync="dialogFormVisible" v-if="showOrder">
        <span style="font-size:14px;margin-right: 6px">https://</span>
        <el-select v-model="domianId" type="danger" class="select_time" allow-create="ture" filterable>
              <el-option
                  v-for="(item,idx) in domianList"
                  :key="idx"
                  :label="item.domianName"
                  :value="item.domianId">
              </el-option>
        </el-select>
        <span style="font-size:14px;margin-left: 6px">example.com</span>
        <div slot="footer">
            <el-button  class="cancel-button" @click="cancel">取 消</el-button>
            <el-button type="primary" class="commit-button" @click="commit">确 定</el-button>
        </div>
    </el-dialog>
<!-- 弹出支付成功  -->
    <!-- 遮罩层 -->
    <el-col class="zz-dialog" v-if="dialogPaySuccess"></el-col>
    <!-- 弹框 -->
    <el-col class="my-dialog" v-if="dialogPaySuccess" >
        <el-col class="img"><img src="../../img/pay/ic_success.png" alt=""></el-col>
        <el-col class="pay-result">支付成功&nbsp;！</el-col>
        <el-col>
          <el-button class="to-shop" @click="to('/cloudMarket')">继续购买</el-button>
          <el-button class="to-home" @click="to('/homeIndex')">返回首页</el-button>
        </el-col>
      <el-col class="nex-container">
      <span class="time">{{countDownNum}}秒</span>
      <span class="todo">后自动跳转返回首页</span>
      </el-col>
    </el-col>
  </div>
</template>

<script>
import productApi from "../../api/software/productApi";
import cloudMarketApi from "../../api/software/cloudMarketApi";
import marketApi from "../../api/cloudSoftMarket/marketApi";

export default {
  name: "cloudMarket",
  data() {
    return {
      trainTable: [],
      title: '',
      tableInfo: [],
      //商品亮点
      marketBrightInfo: {},
      //资源文件
      marketFile: {},
      //客户案例
      marketCaseInfo: {},
      followText: "关注+",
      followFlag: false,
      ecGoodsActivity: {}, // 活动信息
      couponList: [], // 优惠券列表
      amount: 1, // 购买数量
      time: 1, // 购买时长
      price: 0, // 商品价格
      specsArr: [], // 存储对应规格的购买方式
      sepcsType: '', // 规格类型
      totalPrice: 0, // 商品总价
      couponNumber: "", // 优惠券码
      discountPrice: '', // 优惠后价格
      points: "", // 优惠券是否可用提示语
      couponMarketId: "", // 优惠券ID
      buttonDisabled: false, // 是否禁用购买按钮
      buyText: "立即购买",
      isCoupon: false,
      showOrder: true,
      dialogFormVisible: false, // 控制是否弹出dialog
      dialogPaySuccess: false,
      value: 0,
      domianList: [],
      domianName: "", // 域名
      domianId: "",
      status: '', // 域名使用状态
      domianNameInUse: '', // 已经使用过的域名
      countDownNum: 5, // 支付成功跳转首页倒计时
      marketId: '', // 云市场产品Id
      orderType: 0, // 订单类型
      oldOrderId: '', // 旧订单id
      oldOrderNo: '',  // 旧订号
      isSelect: true,
      currentOffset: 0,
      width: "",
      fatherWith: 0,
      marketSpecsName: "",
      box: "", // dom
      productImgCurrentOffset: 0,
      productImgWidth: "",
      productImgFatherWith: 0,
      productImgBox: "",
      productParams: [], // 商品详情
      productSpe: [], // 商品选择版本
      marketSpecsId: '',
      loopData0: [],
      productImgList: [],
      showBuy: true, // 是否显示购买按钮
      productSelect: [
        {
          isSelect: true,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
        {
          isSelect: false,
        },
      ],
      introduceSelect: [
        {
          content: "商品介绍",
          isSelect: true,
        },
        {
          content: "服务商信息",
          isSelect: false,
        },
        {
          content: "操作指南",
          isSelect: false,
        },
        {
          content: "用户评价",
          isSelect: false,
        },
        {
          content: "客户案例",
          isSelect: false,
        },
      ],
      constants: {},
      goodImg: "",
    };
  },
  mounted() {
    this.$store.commit('setCheckIndex', 3);
    this.isFollow(); // 判断用户是否关注该商品
    this.getMarketFull()
    this.getSoftwareGoodsInfoById(); // 获取商品详情
    this.initTrainInfo();
    //
    marketApi.getMarketListByState().then((res) => {
      var list = []
      res.data.forEach( (item) => {
        if (item.marketId !== this.marketId){
          list.push(item)
        }
      })
      this.loopData0 = list
    })
    this.setCardWidth("#father", 4);
    this.box = document.querySelector("#swiper-box");
    // this.productImgBox = document.querySelector("#box_7_left_swiper_box");
    // this.setCardWidthBox7("#box_7_left_parent", 5);
    window.addEventListener("resize", () => {
      this.currentOffset = 0;
      this.setCardWidth("#father", 4);
      this.productImgCurrentOffset = 0;
      // this.setCardWidthBox7("#box_7_left_parent", 5);
    });



  },
  /*  destroyed() {
    this.websocketOnClose();
  },*/
  methods: {
    initTrainInfo() {
      if(this.marketId == '08ed8104-2b8a-4c62-a491-6ac63dee398c') {
        this.trainTable =  [
          {
            "classify": "跨境资源的开通与接入培训",
            "product": "伊登资源培训班",
            "specs": "内容包括：开通跨境资源。<br/>规格：培训时长0.5天，每班最大人数20人",
            "dimension": "0.5天",
            "num": "1",
            "worth": "学习如何成功开通跨境资源，以便顺利接入全球服务网络，为后续跨境合作奠定坚实基础。",
            "price": "120000"
          },
          {
            "classify": "跨境资源的配置与使用培训",
            "product": "伊登资源培训班",
            "specs": "内容包括：跨境资源的配置和使用。<br/>规格：培训时长0.5天，每班最大人数20人",
            "dimension": "0.5天",
            "num": "1",
            "worth": "深入了解并掌握跨境资源的配置和使用方法，通过专业培训提升资源利用效率，确保项目顺利进行。",
            "price": "120000"
          },
          {
            "classify": "跨境流程实操演练",
            "product": "伊登平台培训班",
            "specs": "内容包括：实操演练流程配置及审批。<br/>规格：培训时长0.5天，每班最大人数20人",
            "dimension": "0.5天",
            "num": "1",
            "worth": "通过培训和实操演练，提升跨境项目的执行效率和质量。",
            "price": "120000"
          },
          {
            "classify": "跨境代码托管功能培训",
            "product": "伊登代码培训班",
            "specs": "内容包括：代码托管功能使用培训。<br/>规格：培训时长0.5天，每班最大人数20人",
            "dimension": "0.5天",
            "num": "1",
            "worth": "熟悉并掌握跨境代码托管的核心功能和操作技巧，确保代码安全、高效地托管在全球平台上。",
            "price": "120000"
          },
          {
            "classify": "跨境权限管理及配置菜单培训",
            "product": "伊登平台培训班",
            "specs": "内容包括：平台权限创建、配置，不同角色权限维护，菜单设置。<br/>规格：培训时长0.5天，每班最大人数20人",
            "dimension": "0.5天",
            "num": "1",
            "worth": "学习跨境权限管理的最佳实践和配置菜单的使用技巧，确保项目团队成员能够合理、安全地访问所需资源。",
            "price": "120000"
          }
        ]
      } else if (this.marketId == "6bdf463b-2b97-430e-9b83-df58b908ab44") {
        this.trainTable = [{
          "classify": "项目质量培训",
          "product": "项目质量管理培训班",
          "specs": "内容包括：质量管理理论、质量规划、质量控制、质量保证、标准和合规性、团队协作和沟通、案例研究和实践练习。<br/>规格：培训时长0.5天，每班最大人数25人",
          "dimension": "0.5天",
          "num": "4",
          "worth": "项目质量管理培训的价值在于它能够为组织带来长期和短期的利益，包括提高效率、降低成本、提升客户满意度和增强竞争力。通过提升团队的质量管理能力，组织能够更有效地实现其业务目标。",
          "price": "500000"
        }];
      } else if (this.marketId == "661ebdcf-a0cb-46b5-8c0b-39e7e872a3d9") {
        this.trainTable = [{
          "classify": "项目运维培训",
          "product": "项目运维培训班",
          "specs": "内容包括：基础设施管理、软件部署和配置管理、日志管理和分析、性能优化、安全性管理。<br/>规格：培训时长0.5天，每班最大人数20人",
          "dimension": "0.5天",
          "num": "1",
          "worth": "",
          "price": "50000"
        }];
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },

    // 查询亮点、操作指南、客户案例
    getMarketFull(){
      marketApi.getMarketFull({marketId: this.marketId}).then((res)=>{
        this.marketBrightInfo = res.data.marketBrights;
        this.marketFile = res.data.marketFileList;
        this.marketCaseInfo = res.data.ecMarketCases;
      })
    },
    // 跳转到详情页
    openProductDetail(marketId){
     this.$router.push({name:'cloudMarket',query: {productId: marketId, orderType: 1}})
    },

    // 点击关注事件
    follow() {
      let obj = this;
      // 判断用户是否登录
      if (
        this.$store.state.userInfo.userInfo == "" ||
        this.$store.state.userInfo.userInfo == undefined
      ) {
        this.$router.push({ path: "/login" });
        return;
      }
      if (obj.followFlag) {
        // 已经关注,取消关注,调用取消关注
        obj.delFollow();
      } else {
        // 没有关注，新增关注
        obj.addFollow();
      }
    },
    // 取消关注
    delFollow() {
      let obj = this;
      productApi
        .delFollowByObjectId({ objectId: this.marketId })
        .then((result) => {
          if (result.code == 200) {
            obj.followText = "关注+";
            obj.followFlag = false;
            obj.$message.success("取消关注！");
          }
        });
    },
    // 新增关注
    addFollow() {
      let obj = this;
      productApi
        .addFollowByObjectId({
          objectId: this.marketId,
          type: 1,
          classificationId:
            obj.productParams[0].classifyBrandId == null
              ? obj.productParams[0].classifyFunctionId
              : obj.productParams[0].classifyFunctionId == null
              ? obj.productParams[0].classifyIndustryId == null
              : obj.productParams[0].classifyBrandId,
        })
        .then((res) => {
          if (res.code == 200) {
            obj.$message("已关注！");
            obj.followFlag = true;
            obj.followText = "已关注";
          }
        });
    },
    // 判断用户是否已经关注
    isFollow() {
      let obj = this;
      // 判断用户是否登录
      if (this.$store.state.userInfo.userInfo == "" || this.$store.state.userInfo.userInfo == undefined) {
        return;
      }
      productApi.isFollowByObjectId1({ objectId: this.marketId,type: 1 })
        .then((res) => {
          if (res.code == 200) {
            obj.followFlag = true;
            obj.followText = "已关注";
          } else {
            obj.followFlag = false;
            obj.followText = "关注+";
          }
        });
    },
    // 计算总价
    countTotalPrice() {
      let obj = this;
      //合计
      obj.totalPrice = obj.price * obj.amount;
      obj.totalPrice = obj.totalPrice.toFixed(2);
    },
    // 获取云市场--商品详情数据
    getSoftwareGoodsInfoById() {
      let obj = this;
      cloudMarketApi
        .getMarketInfo({ marketId: this.marketId })
        .then((res) => {
          var marketSpecsInfo = res.data.ecMarketSpecs; // 选择规格
          obj.productParams.push(res.data.marketListVO); // 商品详情
          this.title = this.productParams[0].name + '- 伊登SaaS商城'
          // 商品图片
          obj.goodImg = obj.productParams[0].logoUrl;
          // 选择版本
          marketSpecsInfo.forEach((specsInfo) => {
            specsInfo["isSelect"] = false;
            this.productSpe.push(specsInfo);
            if (specsInfo.yearPrice != null){
              this.tableInfo.push({name: specsInfo.name,type: '按年',price: specsInfo.yearPrice + "/元年"})
            }
            if (specsInfo.monthPrice != null){
              this.tableInfo.push({name: specsInfo.name,type: '按月',price: specsInfo.monthPrice + "/元月"})
            }
            if (specsInfo.freqPrice != null){
              this.tableInfo.push({name: specsInfo.name,type: '次',price: specsInfo.freqPrice + "/元次"})
            }
            if (specsInfo.probation != null){
              this.tableInfo.push({name: specsInfo.name,type: '试用',price: specsInfo.probation + "天"})
            }
            if (specsInfo.probationCount != null){
              this.tableInfo.push({name: specsInfo.name,type: '试用',price: specsInfo.probationCount + "次"})
            }

          });
          // 默认选中第一个版本
          this.productSpe[0].isSelect = true;
          this.marketSpecsId = this.productSpe[0].marketSpecsId;
          // 默认显示第一个版本的购买方式
          this.selectProduct(this.productSpe[0], 0);
          // 初始化单价
          this.price = this.specsArr[0].price;
          this.sepcsType = this.specsArr[0].type;
          // 初始化合计
          this.totalPrice = this.price * this.amount;
        });
      console.log(this.productSpe,'productSpe')
    },
    setCardWidth(id, num) {
      const father = document.querySelector(id);
      this.fatherWith = father.clientWidth;
      this.width = this.fatherWith / num + "px";
    },
    setCardWidthBox7(id, num) {
      const father = document.querySelector(id);
      this.productImgFatherWith = father.clientWidth;
      this.width = this.productImgFatherWith / num + "px";
    },
    right(data, num) {
      if (this.currentOffset <= (-this.fatherWith / num) * (data.length - num))
        return;
      this.currentOffset -= this.fatherWith / num;
    },
    left(data, num) {
      if (this.currentOffset >= -2) return;
      this.currentOffset += this.fatherWith / num;
    },
    box_7_right(data, num) {
      if (
        this.productImgCurrentOffset <=
        (-this.productImgFatherWith / num) * (data.length - num)
      )
        return;
      this.productImgCurrentOffset -= this.productImgFatherWith / num;
    },
    box_7_left(data, num) {
      if (this.productImgCurrentOffset >= -2) return;
      this.productImgCurrentOffset += this.productImgFatherWith / num;
    },
    onClick_1() {
      alert(1);
    },
    // 取消
    cancel() {
      this.dialogFormVisible = false;
      this.domianName = '';
      this.buyText= "立即购买";
      this.buttonDisabled = false;
    },
    // 购买
    commit() {
      let obj = this;
      if (obj.domianId === null || obj.domianId === "") {
        obj.$message.error("请选择或输入域名！");
      } else {
        for (let i = 0; i < obj.domianList.length; i++) {
          if (obj.domianList[i].domianId.includes(obj.domianId)) {
            obj.domianName = obj.domianList[i].domianName;
            obj.status = obj.domianList[i].status;
            break;
          }
        }
        if (obj.status === 1) {
          obj.$message.error("该域名已使用,请换个域名试试！");
        } else {
          obj.syncDomianInfo();
        }
      }
    },
    // 查询个人域名
    getDomianInfo() {
      cloudMarketApi.getDomianInfo().then((result) => {
        if (result.code === 200) {
          this.domianList = result.data;
        }
      });
    },
    getDomianIdByName() {
        let obj = this;
        cloudMarketApi.getDomianIdByName({domianName: this.domianNameInUse}).then((result) => {
            if (result.code === 200) {
              obj.domianId = result.data.domianId;
              // obj.isDisabled = true;
              // obj.domianList.domianName = domianName;
            } else {
              obj.$message.error(result.message);
            }
        });
    },
    syncDomianInfo() {
      let obj = this;
      if (obj.domianName === "" || obj.domianName === null || obj.domianId === obj.domianName) {
        if (obj.domianList.length > 10) {
          obj.$message.error("域名最多只能10个！");
          return;
        }
        obj.domianName = obj.domianId;
        cloudMarketApi.syncDomianInfo({
          domianId: obj.domianId,
          domianName: obj.domianName,
        }).then((result) => {
            if (result.code === 200) {
                obj.$message.success("新增信息成功！");
                // 生成订单
                // if (this.discountPrice !== "" && this.discountPrice === 0) {
                if (this.totalPrice === 0 || (this.discountPrice !== "" && this.discountPrice === 0)) {
                    this.getZeroOrder();
                } else {
                    this.getOrder();
                }
            } else {
              obj.$message.error(result.message);
            }
        })

      } else {
        // 生成订单
          if (this.totalPrice === 0 || (this.discountPrice !== "" && this.discountPrice === 0)) {
              this.getZeroOrder();
        } else {
              this.getOrder();
        }
      }
    },
    toBuyerWork(){
      this.$router.push({path: '/buyer/workOrder'})
    },
    tes() {
      // 查询个人域名
      this.getDomianInfo();
      if (this.domianName === null || this.domianName === "") {
        if (this.orderType === 2) {
            this.getDomianIdByName();
        }
        this.dialogFormVisible = true; // 展示个性域名弹窗
      } else {
          if (this.totalPrice === 0 || (this.discountPrice !== "" && this.discountPrice === 0)) {
          this.getZeroOrder();
          // obj.showSuccessd();
        } else {
          this.getOrder();
        }
      }
    },
    // 根据优惠券码查询优惠金额
    userCoupon() {
      let obj = this;
      cloudMarketApi
        .getCouponNumber({
          couponNumber: obj.couponNumber,
          marketId: obj.productParams[0].marketId,
        })
        .then((result) => {
          if (result.code === 200) {
            obj.isCoupon = true;
            obj.couponMarketId = result.data.couponMarketId;
            let total = (obj.totalPrice * 1000 - result.data.couponPrice * 1000) / 1000;
            if (total < 0) {
              obj.buyText = "立即购买";
              obj.buttonDisabled = false;
              obj.$message.error("该优惠券不能用于该商品！");
              obj.points = "该优惠券不能用于该商品";
              obj.isCoupon = false;
            } else {
              obj.isCoupon = true;
              obj.discountPrice = total; // 优惠后价格
              obj.tes();
              obj.points = "";
            }
          } else {
            obj.buttonDisabled = false;
            obj.buyText = "立即购买";
            obj.$message.error(result.message);
            obj.points = result.message;
          }
        });
    },
    // 立即购买
    buyGood() {
      // 判断用户是否登录
      if (this.$store.state.userInfo.userInfo == "" ||this.$store.state.userInfo.userInfo == undefined) {
        this.$router.push({ path: "/login" });
        return;
      } else {
        this.buyText= "生成订单中...";
        //如果是云文档 或者资产管理
        if (this.productParams[0].name === "云文档管理系统" || this.productParams[0].name === "固定资产智能管理") {
          if (this.couponNumber !== "" && this.couponNumber !== null) {
            this.userCoupon();
          } else {
            this.tes();
          }
        } else {
          this.getOrder();
        }
        this.buyText= "立即购买";
      }
    },
    // 生成订单
    getOrder() {
      let obj = this;
      console.log('that.sepcsType',this.sepcsType)
      cloudMarketApi.generatorOrder({
        'marketId': obj.productParams[0].marketId,
        'shopId': obj.productParams[0].shopId,
        'specsId': obj.marketSpecsId,
        'name': obj.productParams[0].name,
        'type': 1,
        'price': obj.discountPrice,
        'num': obj.amount,
        'totalAmount': obj.totalPrice,
        'specsType': obj.sepcsType,
        'orderType': obj.orderType,
        "oldOrderNo": obj.oldOrderNo,
        "oldOrderId": obj.oldOrderId,
        'couponMarketId': obj.couponMarketId,
        'domianName': obj.domianName,
      }).then((result) => {
        obj.buttonDisabled = false;
        obj.buyText = "立即购买";
        if (result.code === 200) {
          if (obj.sepcsType === "probation" || obj.sepcsType === "probation_count") {
              // window.top.location.href = "/pay?payType=probation";
              this.$router.push({path:'/pay', query: {payType: 'probation'}})
              return;
          }
          // window.location.href = "/pay/index?orderId=" + result.data + "&payType=1";
          this.$router.push({path:'/pay', query: {orderId: result.data, payType: 1}})
         } else {
            obj.$message.error(result.message);
        }
      })
    },
    // 支付0元--生成订单
    getZeroOrder() {
      let obj = this;
      cloudMarketApi.generatorOrder({
        'marketId': obj.productParams[0].marketId,
        'shopId': obj.productParams[0].shopId,
        'specsId': obj.marketSpecsId,
        'name': obj.productParams[0].name,
        'type': 1,
        'price': obj.discountPrice,
        'num': obj.amount,
        'totalAmount': obj.totalPrice,
        'specsType': obj.sepcsType,
        'orderType': obj.orderType,
        "oldOrderNo": obj.oldOrderNo,
        "oldOrderId": obj.oldOrderId,
        'couponMarketId': obj.couponMarketId,
        'domianName': obj.domianName,
      }).then((result) => {
        obj.buttonDisabled = false;
        obj.buyText = "立即购买";
        if (result.code === 200) {
          // 弹出支付弹窗
          obj.dialogPaySuccess = true;
          this.countdownJump();
         } else {
            obj.$message.error(result.message);
        }
      })

    },
    // 倒计时跳转
    countdownJump() {
      let obj = this;
      let time = setInterval(() => {
          if (obj.countDownNum === 0) {
              obj.countDownNum = 5;
              clearInterval(time);
              // window.location.href = "/index";
              this.$router.push({path: '/homeIndex'})
          } else {
              obj.countDownNum--;
          }
      }, 1000)
    },
    // 跳转页面
    to(router) {
      if(router == '/cloudMarket' ){
        this.$router.push({path: router, query: {productId: this.marketId, orderType:this.orderType}});
      }
      this.$router.push({path: router})
    },
    // 切换商品图片
    changeImg(item) {
      this.goodImg = item.goodsImageUrl;
    },
    // 选中规格
    selectProduct(item, index) {
      // 更改选中
      this.productSpe.forEach((item) => {
        item.isSelect = false;
      });
      item.isSelect = true;
      this.marketSpecsId = item.marketSpecsId;
      this.marketSpecsName = item.name;
      // 购买方式改变
      let that = this;
      // that.productSpe[index].marketSpecsId;
      that.specsArr = [];
      const info = that.productSpe[index];
      let obj = {};
      if (info.yearPrice != null) {
        obj = {};
        obj.title = "年";
        obj.price = info.yearPrice;
        obj.type = "year_price";
        obj.isSelect = false;
        that.specsArr.push(obj);
      }
      if (info.monthPrice != null) {
        obj = {};
        obj.title = "月";
        obj.price = info.monthPrice;
        obj.type = "month_price";
        obj.isSelect = false;
        that.specsArr.push(obj);
      }
      if (info.freqPrice != null) {
        obj = {};
        obj.title = "次";
        obj.price = info.freqPrice;
        obj.type = "freq_price";
        obj.isSelect = false;
        that.specsArr.push(obj);
      }
      if (info.probation > 0) {
        obj = {};
        obj.title = info.probation + "天";
        obj.price = 0;
        obj.type = "probation";
        obj.isSelect = false;
        that.specsArr.push(obj);
      }
      if (info.probationount > 0) {
        obj = {};
        obj.title = "试用次数";
        obj.price = 0;
        obj.type = "probation_count";
        obj.isSelect = false;
        that.specsArr.push(obj);
      }
      // 默认选中第一个
      that.specsArr[0].isSelect = true;
      // 初始化价格
      that.price = that.specsArr[0].price;
      // 计算总价
      that.countTotalPrice();
    },
    // 选中购买方式
    selectBuyWay(item, index) {
      let that = this;
      that.specsArr.forEach((specs) => {
        specs.isSelect = false;
      });
      that.specsArr[index].isSelect = true;
      // 初始化价格
      that.price = that.specsArr[index].price;
      // 保存选中的规格类型
      that.sepcsType = item.type;

      // 计算总价
      that.countTotalPrice();
    },
    introduceSelectMethod(item) {
      this.introduceSelect.forEach((item) => {
        item.isSelect = false;
      });
      item.isSelect = true;
    },
  },
  created() {
    document.body.style.overflowX='hidden'
    this.timer = setInterval(this.scroll, this.duration);
    // 获取url携带的参数
    let url = this.$route.fullPath;
    if (url.indexOf("productId") != -1) {
        this.marketId = this.$route.query.productId;
        // 不显示AD域管理平台和企业网盘
        if (this.marketId == 'a3d738f7-e4fe-4732-a2ea-44449a609442' || this.marketId == '19493892-39ec-4d1e-93d4-9c9effc2d734'){
          this.showBuy = false
        }
    }
    if(url.indexOf("orderType") != -1){
        this.orderType = parseInt(this.$route.query.orderType);
        if (this.orderType == 2) {
            this.buyText = "立即续费";
        } else {
            this.buyText = "立即购买";
        }
    }
    if (url.indexOf("oldOrderId") != -1) {
        this.oldOrderId = this.$route.query.oldOrderId;
    }
    if (url.indexOf("oldOrderNo") != -1) {
        this.oldOrderNo = this.$route.query.oldOrderNo;
    }
    if (url.indexOf("domianName") != -1) {
        this.domianNameInUse = this.$route.query.domianName;
    }
    // this.getSoftwareGoodsInfoById();
    // this.isFollow();
  },
  watch:{
    'amount'(newI,oldI) {
      if (newI !== oldI) {
        let obj = this;
        //合计
        obj.totalPrice = obj.price * obj.amount * obj.time;
        obj.totalPrice = obj.totalPrice.toFixed(2);
      }
    },
  }

};
</script>
<style scoped>
.time_input{
  margin: 20px 0;
  color: red;
  margin-left: 15px;
}
/deep/.el-input__inner{
  border: 1px solid #f56e65 !important;
  border-radius: 6px;
}
/deep/ .el-input-number__decrease  {
  color: unset;
  border-right: 1px solid #f56e65;
  background: #ffffff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 41px;
}
/deep/ .el-input-number__increase{
  color: unset;
  border-left: 1px solid #f56e65;
  background: #ffffff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 41px;
}
.work-btn{
  width: 78px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  line-height: 28px;
  text-align: center;
  color: #F56E65;
}
  .introduce_container {
    font-size: 14px;
    color: black;
  }
  .first_row{
    margin-top: 29px;
  }
  .other_row{
    margin-top: 21px;
  }
  .margin-left{
    margin-left: 10%;
  }
  .margin-bright{
    margin-left: 50px;
  }
  /deep/ .el-table thead {
    color: #5e5e5e!important;
  }
</style>
<style lang="scss" scoped>
@import "../../assets/css/common.scss";
@import "../../views/cloudMarket/cloudMarket.scss";
.cursor-item {
  cursor: pointer;
}
.row-roll-product {
  background: #f7f7f7;
}
.slices-img {
  height: 100%;
  width: 10vw;
}
.margin-right {
  margin-right: 1vw;
}
.slices-center {
  justify-content: center;
  display: flex;
}
.bg-purple {
  overflow: hidden;
  position: relative;
}
.swiper-box {
  display: flex;
  width: auto;
  transition: all 0.2s;
}

[class^="el-icon-arrow-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  padding: 10px 0;
  cursor: pointer;
}
.select_color {
  background-color: #3e3e3e;
}
.no_select_color {
  background-color: #f6f6f6;
}
.select_font_color {
  color: white;
}
.no_select_font_color {
  color: #414141;
}
.product_scroll::-webkit-scrollbar {
  display: none;
}
.product_scroll {
  max-height: 17vw;
  overflow-y: auto;
  margin-top: 20px;
}

.introduce_select {
  color: black;
}

.introduce_no_select {
  color: #414141;
}
@media screen and (max-width: 767px) {
}

/* 4. 大桌面显示器（大于等于768px）布局容器宽度为768px */
@media screen and (min-width: 768px) {
}
</style>
