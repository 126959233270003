import {get,post} from "@/utils/axios"

// 获取云市场商品详情
const getMarketInfo = p => get('/api-common/market/getMarketInfo', p);

// 根据优惠券码查询优惠金额
const getCouponNumber = p => post('/api-seller/marketCoupon/getCouponNumber', p)

// // 查询个人域名
const getDomianInfo = p => get('/api-seller/domian/getDomianInfo', p)

const getDomianIdByName = p => get('/api-seller/domian/getDomianIdByName', p)

const syncDomianInfo = p => post('/api-seller/domian/syncDomianInfo', p)

// 生成订单
const generatorOrder = p => post('/api-seller/apiConsoleGoods/generatorOrder', p)

const generatorOrders = p => post('/api-seller/apiConsoleGoods/generatorOrders', p)

export default {
    getMarketInfo,
    getCouponNumber,
    getDomianInfo,
    getDomianIdByName,
    syncDomianInfo,
    generatorOrder,
    generatorOrders,
}
